import { translateServer } from "@ollie-sports/i18n";
import {
  AccountId,
  FundraiserBasicData,
  FundraisingReminderPreset,
  LowPriorityNotificationDetailType,
  NotificationType,
  PushNotificationSettingToRespect,
  Team
} from "@ollie-sports/models";
import moment from "moment";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { ObjectKeys } from "../../utils";
import { generateAndProcessNotifications } from "../notification/notification.plumbing";

export async function fundraiser__server__sendReminders(p: {
  teamAndFundraiserData: {
    team: Team;
    fundraiserData: FundraiserBasicData;
  }[];
  message: string;
  senderName: string;
  selfAccountId: AccountId;
}) {
  const { appOllieFirestoreV2: h } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();
  const nowMS = Date.now();

  await Promise.all(
    p.teamAndFundraiserData.map(data => {
      try {
        const accountIds = ObjectKeys(data.team.accounts);
        return generateAndProcessNotifications({
          data: accountIds
            .filter(accountId => accountId !== p.selfAccountId)
            .map(accountId => {
              return { accountId };
            }),
          generateFn: async a => {
            const communicationLocale = a.accountPrivate.communicationLocale;

            const account = await h.Account.getDoc(a.accountPrivate.id);
            if (!account) {
              throw new Error("Notification sent to user without account?!?");
            }

            const title = translateServer(
              { defaultMessage: `Reminder From {name}`, serverLocale: communicationLocale },
              { name: p.senderName }
            );

            const routerPath = `main/money/fundraisingTeamHomePage/${data.team.id}/${data.fundraiserData.flexTeamId}/generic-share`;

            return getNotificationGeneratorInfo({
              idempotencyKey: "fundraiser-reminder" + nowMS + data.team.id + data.fundraiserData.flexTeamId,
              accountEmail: account.email,
              expireAtMS: moment().add(6, "days").valueOf(),
              message: p.message,
              title,
              routerPath
            });
          }
        });
      } catch (e) {
        olliePipe.emitEvent({ type: "error-sending-fundraising-reminder", payload: e });
        return null;
      }
    })
  );

  for (let i = 0; i < p.teamAndFundraiserData.length; i++) {
    const data = p.teamAndFundraiserData[i];
    await h.Team.update({
      id: data.team.id,
      doc: {
        fundraiserRemindersSent: {
          [data.fundraiserData.flexTeamId]: {
            senderName: p.senderName,
            sentAtMS: Date.now()
          }
        }
      }
    });
  }
}

function getNotificationGeneratorInfo(p: {
  idempotencyKey: string;
  message: string;
  title: string;
  expireAtMS: number;
  routerPath: string;
  accountEmail: string;
}) {
  const { expireAtMS, idempotencyKey, message, title, accountEmail, routerPath } = p;
  return {
    idempotencyKey,
    notificationType: NotificationType.lowPriorityNotification,
    type: "lowPriorityNotification" as const,
    lowPriorityNotificationDetail: {
      body: message,
      title: title,
      expireAtMS,
      routerPath,
      type: LowPriorityNotificationDetailType.fundraiserReminder
    },
    realTimeNotification: {
      e: expireAtMS
    },
    pushNotificationData: {
      body: message,
      title,
      lowPriorityNotificationDetailType: LowPriorityNotificationDetailType.fundraiserReminder,
      pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
      routerPath
    }
  };
}

fundraiser__server__sendReminders.auth = () => {};
